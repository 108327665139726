import {
  Component,
  PlanAnnotation,
  Rect,
  ReviewResultType,
} from "../../../model/blueprintServer";
import sectionComplianceReportColors from "../../../styles/complianceReport.scss";

export interface CanvasAnnotation {
  componentId: string;
  pageNumber: number;
  isHovered: boolean;
  isSelected: boolean;
  rect: Rect;
  label: string;
  theme: AnnotationTheme;
  priority?: number;
}

export interface AnnotationTheme {
  color: string;
}

const DEFAULT_THEME: AnnotationTheme = {
  color: "#000",
};

export const planAnnotationToCanvasAnnotation = (
  planAnnotation: PlanAnnotation,
): CanvasAnnotation => ({
  isHovered: false,
  isSelected: false,
  componentId: planAnnotation.id,
  pageNumber: planAnnotation.pageNumber,
  rect: planAnnotation.rect,
  label: planAnnotation.label,
  theme: DEFAULT_THEME,
});

export const componentToCanvasAnnotation = (
  component: Component,
  pageNumber: number,
  theme?: AnnotationTheme,
  priority: number = 0,
): CanvasAnnotation => ({
  isHovered: false,
  isSelected: false,
  componentId: component.componentId,
  pageNumber,
  rect: component.rect,
  label: component.data.title,
  theme: theme ?? DEFAULT_THEME,
  priority,
});

const {
  colorDeeperReviewBg,
  colorNotEnoughDataBg,
  colorNonComplianceBg,
  colorOnSiteVerificationBg,
  colorNotApplicableBg,
  colorComplianceBg,
} = sectionComplianceReportColors;

export const getColorForStatus = (
  reviewResultType: ReviewResultType,
): string => {
  const statusToColorMap: Record<ReviewResultType, string> = {
    [ReviewResultType.COMPLIANCE]: colorComplianceBg,
    [ReviewResultType.NON_COMPLIANCE]: colorNonComplianceBg,
    [ReviewResultType.NOT_APPLICABLE]: colorNotApplicableBg,
    [ReviewResultType.ON_SITE_VERIFICATION]: colorOnSiteVerificationBg,
    [ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS]:
      colorNotEnoughDataBg,
    [ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO]:
      colorNotEnoughDataBg,
    [ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO]:
      colorNotEnoughDataBg,
    [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION]:
      colorNotEnoughDataBg,
    [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS]: colorNotEnoughDataBg,
    [ReviewResultType.NO_ENOUGH_DATA_OTHER]: colorNotEnoughDataBg,
    [ReviewResultType.DEEPER_REVIEW]: colorDeeperReviewBg,
  };

  return statusToColorMap[reviewResultType];
};

export const getTheme = (
  reviewResultType: ReviewResultType,
): AnnotationTheme => ({
  color: getColorForStatus(reviewResultType),
});

const reviewResultTypePriority: Record<ReviewResultType, number> = {
  [ReviewResultType.COMPLIANCE]: 2,
  [ReviewResultType.NON_COMPLIANCE]: 6,
  [ReviewResultType.ON_SITE_VERIFICATION]: 3,
  [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION]: 4,
  [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS]: 4,
  [ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO]: 4,
  [ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO]: 4,
  [ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS]: 4,
  [ReviewResultType.NO_ENOUGH_DATA_OTHER]: 4,
  [ReviewResultType.DEEPER_REVIEW]: 5,
  [ReviewResultType.NOT_APPLICABLE]: 1,
};

export const getPriority = (reviewResultType: ReviewResultType): number =>
  reviewResultTypePriority[reviewResultType];
