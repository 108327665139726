import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextareaAutosize from "react-textarea-autosize";
import "./questionBox.scss";
import { atom, useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useBookMetadataData } from "../../../atom/bookMetadataAtom";
import { CodeAndBookSelector } from "./CodeAndBookSelector";
import { Button, ButtonColor } from "../../theme/Button";
import { BookMetadata, BookType } from "../../../model/blueprintServer";

interface SelectedCodesAtom {
  bookType: BookType | null;
  bookGroup: string | null;
  selectedBookMetadata: BookMetadata | null;
  selectedBooksMetadata: BookMetadata[];
}

export const selectedCodesAtom = atom<SelectedCodesAtom>({
  key: "selectedCodes",
  default: {
    bookType: null,
    bookGroup: null,
    selectedBookMetadata: null,
    selectedBooksMetadata: [],
  },
});

interface QuestionBoxProps {
  handleSubmit: (
    question: string,
    booksMetadata: BookMetadata[],
    onFinish: () => void,
  ) => void;
}

export const QuestionBox = ({ handleSubmit }: QuestionBoxProps) => {
  const [question, setQuestion] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { model: project } = useRecoilValue(currentProjectsAtom);
  const [codeYear, setCodeYear] = useState(0);
  const jurisdictionToBooksData = useBookMetadataData();
  const selectedCodes = useRecoilValue(selectedCodesAtom);

  useEffect(() => {
    if (!codeYear && project) {
      setCodeYear(project.jurisdiction.codeYear);
    }
  }, [project, codeYear, setCodeYear]);

  useEffect(() => {
    textareaRef?.current?.focus();
  }, [textareaRef]);

  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const handleFinishLoading = useCallback(() => {
    setLoading(false);
    setQuestion("");
  }, [setLoading, setQuestion]);

  const handleAskQuestion = useCallback(() => {
    const { selectedBooksMetadata, selectedBookMetadata } = selectedCodes;
    let booksMetadata = [...selectedBooksMetadata];
    if (selectedBookMetadata) {
      booksMetadata = [...booksMetadata, selectedBookMetadata];
    }
    setLoading(true);
    handleSubmit(question, booksMetadata, handleFinishLoading);
  }, [handleSubmit, question, setLoading, selectedCodes, handleFinishLoading]);

  const enableSubmit = useMemo(
    () =>
      (!!selectedCodes.selectedBooksMetadata.length ||
        selectedCodes.selectedBookMetadata) &&
      question.length > 10,
    [selectedCodes, question],
  );

  const submitText = useMemo(() => {
    const { selectedBookMetadata, selectedBooksMetadata } = selectedCodes;
    if (selectedBooksMetadata.length > 1) {
      return "Multiple code books are selected";
    }

    if (selectedBooksMetadata.length === 1) {
      return `Question is about ${selectedBooksMetadata[0].year} ${selectedBooksMetadata[0].groupId}`;
    }

    if (selectedBookMetadata) {
      return `Question is about ${selectedBookMetadata.year} ${selectedBookMetadata.groupId}`;
    }

    return "Select at least one code book";
  }, [selectedCodes]);

  if (!project || jurisdictionToBooksData.isNotDone()) {
    return null;
  }

  return (
    <div className="question-box flex">
      <div className="code-form">
        <div className="flex book-selector-container">
          <CodeAndBookSelector books={jurisdictionToBooksData.data()} />
        </div>
      </div>
      <div className="text-input">
        <TextareaAutosize
          onChange={handleChange}
          maxLength={10000}
          minRows={4}
          value={question}
          ref={textareaRef}
          disabled={loading}
          placeholder="Is the egress..."
        />
        <div className="flex submit-container">
          <div className="hint">{submitText}</div>
          <Button
            color={ButtonColor.DARK}
            onClick={handleAskQuestion}
            disabled={!enableSubmit}
          >
            Ask
          </Button>
        </div>
      </div>
    </div>
  );
};
