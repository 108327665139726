import { CanvasAnnotation } from "../util/canvasAnnotationUtil";
import { calculateLineThickness } from "../util/canvasSizeUtil";
import { shadeColor } from "../../../util/colorUtil";

export const reactForAnnotation =
  (annotations: CanvasAnnotation[]) => (ctx: CanvasRenderingContext2D) => {
    for (const annotation of annotations) {
      const { start, end } = annotation.rect;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const minX = Math.min(start.x, end.x);
      const minY = Math.min(start.y, end.y);
      const w = Math.max(start.x, end.x) - minX;
      const h = Math.max(start.y, end.y) - minY;
      const thickness = calculateLineThickness(ctx);

      ctx.lineWidth =
        annotation.isHovered || annotation.isSelected
          ? thickness * 1.5
          : thickness;
      ctx.strokeStyle = annotation.isSelected
        ? shadeColor(annotation.theme.color, 30)
        : annotation.theme.color;
      if (annotation.isSelected) {
        ctx.lineWidth = thickness;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 90);
      } else if (annotation.isHovered) {
        ctx.lineWidth = thickness * 1.5;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 90);
      } else {
        ctx.lineWidth = thickness;
        ctx.strokeStyle = shadeColor(annotation.theme.color, 0);
      }
      ctx.fillStyle = shadeColor(annotation.theme.color, 0);
      ctx.globalAlpha = 0.07;
      ctx.fillRect(x, y, w, h);
      ctx.globalAlpha = 0.3;
      ctx.strokeRect(x, y, w, h);
    }
  };
