import React, { useCallback, useEffect, useState } from "react";
import { useCurrentPlanReportData } from "../../atom/currentPlanReportAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { assertDefined } from "../../util/assertions";
import {
  Component,
  DataLoadingStates,
  SectionComplianceReport,
} from "../../model/blueprintServer";
import { SideBySideView } from "../sideBySide/SideBySideView";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";
import "./planReportPage.scss";
import { appMenuStateAtom } from "../../atom/MenuStateAtom";
import { SideBySideContentForReport } from "../sideBySide/SideBySideContentForReport";
import { filteredSectionComplianceReport } from "./atom/filteredPlanReportSectionsAtom";
import {
  CanvasAnnotation,
  componentToCanvasAnnotation,
  getPriority,
  getTheme,
} from "../canvas/util/canvasAnnotationUtil";
import { useLoadFullPlan } from "../projects/hook/useLoadFullPlan";
import { useFullPlanData } from "../../atom/fullPlanAtom";

export const PlanReportPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planReportData = useCurrentPlanReportData();
  const setAppMenuState = useSetRecoilState(appMenuStateAtom);
  useLoadFullPlan();
  const [collapseAppSidebar, setCollapseAppSidebar] = useState(false);
  const sections = useRecoilValue(filteredSectionComplianceReport);
  const filteredSections = useRecoilValue(filteredSectionComplianceReport);
  const fullPlanData = useFullPlanData();

  useEffect(() => {
    if (!collapseAppSidebar) {
      setAppMenuState((old) => ({ ...old, collapse: true }));
      setCollapseAppSidebar(true);
    }
  }, [collapseAppSidebar, setAppMenuState, setCollapseAppSidebar]);

  const getCanvasAnnotations = useCallback(
    (pageIndex: number) => {
      if (fullPlanData.isNotDone()) {
        return [];
      }

      const page = fullPlanData.data().pages[pageIndex];
      if (!page) {
        return [];
      }

      const annotations: CanvasAnnotation[] = [];
      filteredSections.flat().forEach((section) => {
        section.markedBlueprintLocation.forEach((ref) => {
          const component = page.components.find(
            (c) => c.componentId === ref.componentId,
          );
          if (component) {
            annotations.push(
              componentToCanvasAnnotation(
                component,
                pageIndex,
                getTheme(section.finalAssessment),
                getPriority(section.finalAssessment),
              ),
            );
          }
        });
      });
      return annotations;
    },
    [fullPlanData, filteredSections],
  );

  if (planReportData.isNotDone() || fullPlanData.isNotDone()) {
    return <AppLogoLoader />;
  }

  if (planReportData.data().status === DataLoadingStates.ERROR) {
    return (
      <div className="plan-report-error">
        We experienced an error while generating this report
      </div>
    );
  }

  if (planReportData.data().status !== DataLoadingStates.COMPLETED) {
    return <div>Creating report, we will email you when it is ready</div>;
  }

  const project = assertDefined(currentProject.model);
  return (
    <div className="plan-report-page">
      <SideBySideView<SectionComplianceReport>
        items={sections}
        cityName={project.jurisdiction.cityName}
        RightHandSide={SideBySideContentForReport}
        title={planReportData.data().title}
        additionalTitle={`Created ${new Date(
          planReportData.data().created,
        ).toDateString()}`}
        getCanvasAnnotations={getCanvasAnnotations}
        enableAnnotationTooltip={true}
      />
    </div>
  );
};
