import { useCanvasBase } from "./useCanvasBase";
import { flashRectangleBorders } from "./painter/flashRectPainter";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import {
  SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
  ShowPageInPageView,
} from "../markdown/PageRefLink";
import { CanvasAnnotation } from "./util/canvasAnnotationUtil";
import { BlobStorage } from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import { useCallback, useEffect, useState } from "react";

export const useFlashingRectangleCanvasLayer = (
  annotations: CanvasAnnotation[],
  pageImage: BlobStorage,
  isLoadingImage: boolean,
) => {
  const { drawFrame, Canvas } = useCanvasBase("selected-annotation");
  const [registeredComponentId, setRegisteredComponentId] = useState("");

  const flashAnnotation = useCallback(
    (componentId: string) => {
      const annotation = annotations.find((c) => c.componentId === componentId);
      if (!annotation) {
        setRegisteredComponentId(componentId ?? "");
        return;
      }

      setTimeout(
        () =>
          drawFrame(
            [flashRectangleBorders(annotation.rect)],
            formatImageUrl(pageImage),
          ),
        200,
      );
    },
    [drawFrame, annotations, pageImage],
  );

  const registerFlash = (event: any) => {
    const { componentId } = event.detail as ShowPageInPageView;

    if (!componentId) {
      return;
    }

    flashAnnotation(componentId);
  };

  useEffect(() => {
    if (registeredComponentId && !isLoadingImage) {
      flashAnnotation(registeredComponentId);
      setRegisteredComponentId("");
    }
  }, [
    setRegisteredComponentId,
    registeredComponentId,
    isLoadingImage,
    flashAnnotation,
  ]);

  useBrowserEventListenerEvent(
    SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
    registerFlash,
  );

  return Canvas;
};
