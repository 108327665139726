import { PagesIndex } from "../../planReport/PagesIndex";
import { PageCanvas } from "../../sideBySide/PageCanvas";
import React, { useMemo, useState } from "react";
import "./planViewer.scss";
import { useHighlightedPageAndComponentListener } from "./useHighlightedPageAndComponentListener";

import { CanvasAnnotation } from "../../canvas/util/canvasAnnotationUtil";
import { useRecoilState } from "recoil";
import { planReportFiltersAtom } from "../../planReport/atom/PlanReportFiltersAtom";
import { useFullPlanData } from "../../../atom/fullPlanAtom";

interface PlanViewerProps {
  getCanvasAnnotations: (pageIndex: number) => CanvasAnnotation[];
  enableAnnotationTooltip: boolean;
}

export const PlanViewer = ({
  enableAnnotationTooltip,
  getCanvasAnnotations = () => [],
}: PlanViewerProps) => {
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const fullPlanData = useFullPlanData();
  const [{ pageIndex }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );

  const setPageIndex = (newPageIndex: number) => {
    if (fullPlanData.isNotDone()) {
      return;
    }

    if (
      newPageIndex > -1 &&
      newPageIndex < fullPlanData.data().pages.length &&
      newPageIndex !== pageIndex
    ) {
      setPlanReportFilters((old) => ({ ...old, pageIndex: newPageIndex }));
      setIsLoadingImage(true);
    }
  };
  useHighlightedPageAndComponentListener(setPageIndex, () => {});

  const filteredAnnotations = useMemo(
    () => getCanvasAnnotations(pageIndex),
    [getCanvasAnnotations, pageIndex],
  );

  if (fullPlanData.isNotDone()) {
    return null;
  }

  return (
    <div className="plan-viewer flex">
      <PagesIndex setPageIndex={setPageIndex} />
      <PageCanvas
        setPageIndex={setPageIndex}
        page={fullPlanData.data().pages[pageIndex]}
        filteredAnnotations={filteredAnnotations}
        handleFinishLoadImage={() => setIsLoadingImage(false)}
        isLoadingImage={isLoadingImage}
        enableAnnotationTooltip={enableAnnotationTooltip}
      />
    </div>
  );
};
