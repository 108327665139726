import {
  BuildingCodeRecord,
  BookType,
  CityName,
  BookMetadata,
} from "../model/blueprintServer";

export const codeSymbol = (buildingCode: BuildingCodeRecord) => {
  if (
    buildingCode.city === CityName.NEW_YORK_CITY &&
    buildingCode.year === 1968
  ) {
    return "§";
  }
};

export const getCodeTypeText = (
  buildingCodeType: BookType | null,
  short = false,
) => {
  switch (buildingCodeType) {
    case BookType.BUILDING_CODE:
      if (short) {
        return "BC";
      }
      return "Building code";
    case BookType.ACCESSIBILITY:
      return "Accessibility";
    default:
      return "";
  }
};

export type BooksGroup = Record<string, BookMetadata[]>;

export type GroupedBookTypes = Record<BookType, BooksGroup>;

export function groupBooksByType(books: BookMetadata[]): GroupedBookTypes {
  const groupedBooks: GroupedBookTypes = {
    [BookType.BUILDING_CODE]: {},
    [BookType.ACCESSIBILITY]: {},
  };

  books.forEach((book) => {
    const { type, groupId } = book;

    if (!groupedBooks[type]) {
      groupedBooks[type] = {};
    }

    if (!groupedBooks[type][groupId]) {
      groupedBooks[type][groupId] = [];
    }

    if (!groupedBooks[type][groupId].includes(book)) {
      groupedBooks[type][groupId].push(book);
    }
  });

  return groupedBooks;
}
