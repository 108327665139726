import { useEffect } from "react";
import { planApi } from "../../../api/planApi";
import { useActivePlan } from "./useActivePlan";
import { fullPlanAtom, useFullPlanData } from "../../../atom/fullPlanAtom";
import { useResetRecoilState } from "recoil";

export const useLoadFullPlan = () => {
  const { activePlan } = useActivePlan();
  const fullPlanData = useFullPlanData();
  const resetFullPlanAtom = useResetRecoilState(fullPlanAtom);

  useEffect(() => {
    if (!activePlan) {
      resetFullPlanAtom();
      return;
    }

    if (fullPlanData.isLoading()) {
      return;
    }

    if (fullPlanData.nullableData()?.id === activePlan?.id) {
      return;
    }

    fullPlanData.startLoading();
    planApi.getFullPlan(activePlan.id).then((plan) => {
      fullPlanData.update(plan);
      fullPlanData.endLoading();
    });
  }, [activePlan, fullPlanData, resetFullPlanAtom]);
};
