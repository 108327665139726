import React, { useCallback, useMemo } from "react";
import { Box } from "../../theme/Box";
import { useActivePlan } from "../hook/useActivePlan";
import { usePlanReportsList } from "../../../atom/planReportListAtom";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { Button } from "../../theme/Button";
import { DataLoadingStates, PlanReport } from "../../../model/blueprintServer";
import { SpinnerLoader } from "../../other/SpinnerLoader";
import "./reportsList.scss";
import { niceDate } from "../../../util/dateUtil";

const EmptySpinner = () => <SpinnerLoader />;

export const ReportsList = () => {
  const { activePlan, isProcessingPlan } = useActivePlan();
  const planReportList = usePlanReportsList();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const navigate = useNavigate();

  const getActionButton = useCallback(
    (report: PlanReport) => {
      if (!activePlan || planReportList.isNotDone()) {
        return <EmptySpinner />;
      }

      if (report.status !== DataLoadingStates.COMPLETED) {
        return <EmptySpinner />;
      }

      const link =
        "/" +
        generatePath(ROUTER_PATHS.PLAN_REPORT, {
          projectId: currentProject?.model?.projectId,
          planId: activePlan.id,
          reportId: report.reportId,
        });
      return <Button onClick={() => navigate(link)}>Open</Button>;
    },
    [currentProject, activePlan, navigate, planReportList],
  );

  const list = useMemo(() => {
    if (!activePlan) {
      return <div>Upload your first plan</div>;
    }

    if (planReportList.isNotDone()) {
      return <MultiLinearLoader />;
    }

    if (isProcessingPlan) {
      return <div>Your plan is processing</div>;
    }

    const items = planReportList.data().map((report) => {
      return (
        <tr key={report.reportId}>
          <td>{report.title}</td>
          <td>{niceDate(report.created)}</td>
          <td className="open-link">{getActionButton(report)}</td>
        </tr>
      );
    });

    if (planReportList.data().length === 0) {
      return (
        <div className="empty">
          <Link to="/contact">Contact</Link> us to request a report
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  }, [activePlan, isProcessingPlan, getActionButton, planReportList]);

  return <Box body={list} title="Reports" classes="reports-list" />;
};
