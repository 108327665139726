import { createBrowserRouter } from "react-router-dom";
import { Page } from "./Page";
import { Contact } from "../contact/Contact";
import { Privacy } from "../privacyAndTerms/Privacy";
import { Terms } from "../privacyAndTerms/Terms";
import { ErrorPage } from "./ErrorPage";
import { PlanQaAnnotator } from "../projects/plan/PlanQaAnnotator";
import { ROUTER_PATHS } from "./routerPaths";
import { Projects } from "../projects/project/Projects";
import { CurrentPlanPage } from "../projects/project/CurrentPlanPage";
import { AdminPlanViewer } from "../admin/AdminPlanViewer";
import { PlanReportPage } from "../planReport/PlanReportPage";
import { PlanQaPage } from "../projects/questions/PlanQaPage";
import { PlanViewerPage } from "../projects/plan/PlanViewerPage";
import { OrganizationPage } from "../organization/OrganizationPage";
import { SupportPage } from "../support/SupportPage";
import { AdminReportUI } from "../admin/AdminReportUI";
import { AdminMarkdownTester } from "../admin/AdminMarkdownTester";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Page />
      </>
    ),
    children: [
      { path: "", element: <Projects /> },
      { path: ROUTER_PATHS.ROOT, element: <Projects /> },
      { path: ROUTER_PATHS.PROJECT_SETTINGS, element: <CurrentPlanPage /> },
      { path: ROUTER_PATHS.PLAN_VIEWER, element: <PlanViewerPage /> },
      {
        path: ROUTER_PATHS.PLAN_QA_ANNOTATION,
        element: <PlanQaAnnotator />,
      },
      {
        path: ROUTER_PATHS.PLAN_QA,
        element: <PlanQaPage />,
      },
      {
        path: ROUTER_PATHS.PLAN_REPORT,
        element: <PlanReportPage />,
      },
      { path: ROUTER_PATHS.ORGANIZATION, element: <OrganizationPage /> },
      { path: ROUTER_PATHS.SUPPORT, element: <SupportPage /> },
      { path: "contact", element: <Contact /> },
      { path: "privacy", element: <Privacy /> },
      { path: "terms", element: <Terms /> },

      { path: "admin/plan", element: <AdminPlanViewer /> },
      { path: "admin/report", element: <AdminReportUI /> },
      { path: "admin/markdown", element: <AdminMarkdownTester /> },
    ],
    errorElement: <ErrorPage />,
  },
]);
