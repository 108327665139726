import React, { useCallback, useEffect, useMemo } from "react";
import "./projectDetails.scss";
import { useRecoilState, useResetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { projectApi } from "../../../api/projectApi";
import { CityName, ProjectSize } from "../../../model/blueprintServer";
import { Dropdown } from "../../other/dropdown/Dropdown";
import { Input } from "../../other/Input";
import { getCityDisplayName } from "../../../util/addressUtil";
import { assertDefined } from "../../../util/assertions";
import { Box } from "../../theme/Box";
import { useParams } from "react-router-dom";
import { formatCodeYear } from "../../../util/dateUtil";

export const INVESTIGATION_YEAR = -1;

export const getSupportedYears = (cityName: CityName) => {
  if (cityName === CityName.NEW_YORK_CITY) {
    return [2022, 2008, 1968, 1938];
  }

  if (cityName === CityName.PHILADELPHIA) {
    return [2018];
  }

  if (cityName === CityName.NEW_JERSEY) {
    return [2021];
  }

  return [];
};

export const ProjectDetails = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);
  const resetCurrentProject = useResetRecoilState(currentProjectsAtom);
  const { projectId } = useParams();

  useEffect(() => {
    if (!projectId) {
      resetCurrentProject();
    }
  }, [projectId, resetCurrentProject]);

  const updateProjectSize = useCallback(
    (projectSize: ProjectSize) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = { ...old, model: { ...old.model, projectSize } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateProjectType = useCallback(
    (projectType: string) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = { ...old, model: { ...old.model, projectType } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateCodeYear = useCallback(
    (codeYear: number) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = {
          ...old,
          model: {
            ...old.model,
            jurisdiction: {
              ...old.model.jurisdiction,
              codeYear: Number(codeYear),
            },
          },
        };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateAreaName = useCallback(
    (areaName: string) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = {
          ...old,
          model: {
            ...old.model,
            jurisdiction: { ...old.model.jurisdiction, areaName },
          },
        };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const project = assertDefined(currentProject.model);

  const details = useMemo(() => {
    return (
      <div className="details flex">
        <div className="project-item">
          <div className="label">
            <span>Type</span>
          </div>
          <Dropdown
            value={project.projectType}
            values={["Commercial", "Residential", "Mixed used", "School"]}
            onChange={(val) => updateProjectType(val)}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Size</span>
          </div>
          <Dropdown
            value={project.projectSize}
            values={[
              ProjectSize._1000,
              ProjectSize._5000,
              ProjectSize._10000,
              ProjectSize._20000,
              ProjectSize._50000,
              ProjectSize._100000,
            ]}
            valueFn={(p) => {
              if (!p) {
                return "";
              }
              const numString = p.slice(1, p.length);
              const formatter = new Intl.NumberFormat("en-US");
              const formattedNumber = formatter.format(Number(numString));
              return `${formattedNumber} sqft`;
            }}
            onChange={(val) => updateProjectSize(val)}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Code year</span>
          </div>
          <Dropdown
            value={project.jurisdiction.codeYear}
            values={getSupportedYears(project.jurisdiction.cityName)}
            onChange={(val) => updateCodeYear(val)}
            valueFn={formatCodeYear}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Area</span>
          </div>
          <Input
            value={getCityDisplayName(project.address.city)}
            disabled={true}
            onChange={updateAreaName}
          />
        </div>
      </div>
    );
  }, [
    project,
    updateProjectType,
    updateProjectSize,
    updateAreaName,
    updateCodeYear,
  ]);

  return (
    <div className="project-details flex">
      <Box title="Setup" body={details} />
      {/*<Box title="Scope" body={scope} />*/}
    </div>
  );
};
