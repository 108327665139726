import { useAnnotationCanvasLayer } from "./useAnnotationCanvasLayer";
import { PlanPage, Rect, XYPoint } from "../../model/blueprintServer";
import { CanvasAnnotation } from "./util/canvasAnnotationUtil";
import { useFlashingRectangleCanvasLayer } from "./useFlashingRectangleCanvasLayer";

export const useCreateAllCanvasLayers = (
  page: PlanPage,
  canvasAnnotations: CanvasAnnotation[],
  handleAddAnnotation: (pageNumber: number, rect: Rect) => void,
  handleClickOnAnnotation: (
    annotationKey: string | null,
    point: XYPoint | null,
  ) => void,
  isLoadingImage: boolean,
) => {
  const AnnotationCanvas = useAnnotationCanvasLayer(
    page.pageImageBlob,
    page.pageNumber,
    canvasAnnotations,
    handleAddAnnotation,
    handleClickOnAnnotation,
  );

  const SelectedAnnotationCanvas = useFlashingRectangleCanvasLayer(
    canvasAnnotations,
    page.pageImageBlob,
    isLoadingImage,
  );

  return (
    <>
      {SelectedAnnotationCanvas}
      {AnnotationCanvas}
    </>
  );
};
