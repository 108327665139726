import { Tooltip } from "react-tooltip";
import { BuildingCodeCard } from "../projects/questions/BuildingCodeCard";
import React from "react";
import { BuildingCodeCitation } from "./parseCitationUtil";
import "./buildingCodeRefLink.scss";

interface CitationLinkProps {
  text: string;
  citation: BuildingCodeCitation;
}

export const BuildingCodeRefLink = ({ text, citation }: CitationLinkProps) => {
  return (
    <span
      className="building-code-ref-link"
      data-tooltip-id={citation.section + "card"}
    >
      {text}
      <Tooltip
        id={citation.section + "card"}
        place="bottom-start"
        clickable
        className="building-code-card"
      >
        <BuildingCodeCard
          fullSectionPath={citation.section}
          bookId={citation.bookId}
          cityName={citation.cityName}
          codeYear={citation.year}
        />
      </Tooltip>
    </span>
  );
};
