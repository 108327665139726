import { atom, RecoilState, SetterOrUpdater, useRecoilState } from "recoil";
import { DataState } from "./projectsAtom";
import { useMemo } from "react";
import { assertDefined } from "../util/assertions";

export interface BaseAtomDataI<T> {
  dataState: DataState;
  model: T | null;
}

export class AtomDataClass<T> {
  private _data: BaseAtomDataI<T>;
  private readonly setData: SetterOrUpdater<BaseAtomDataI<T>>;

  constructor(
    data: BaseAtomDataI<T>,
    setData: SetterOrUpdater<BaseAtomDataI<T>>,
  ) {
    this._data = data;
    this.setData = setData;
  }

  isLoading() {
    return this._data.dataState === DataState.LOADING;
  }

  isNotDone() {
    return this._data.dataState !== DataState.LOADED;
  }

  isDone() {
    return this._data.dataState === DataState.LOADED;
  }

  isNotLoaded() {
    return this._data.dataState === DataState.NOT_LOADED;
  }

  hasData() {
    return !!this._data.model;
  }

  data(): T {
    if (this.isNotDone()) {
      throw new Error("Tried to get data, but the atom is loading");
    }

    return assertDefined(this._data.model);
  }

  nullableData(): T | null {
    return this._data.model;
  }

  update(newModel: any): AtomDataClass<T> {
    this.setData((old) => ({ ...old, model: newModel }));
    return this;
  }

  updateFn(fn: (currVal: BaseAtomDataI<T>) => BaseAtomDataI<T>) {
    this.setData(fn);
    return this;
  }

  startLoading(): AtomDataClass<T> {
    this.setData((old) => ({ ...old, dataState: DataState.LOADING }));
    return this;
  }

  endLoading(): AtomDataClass<T> {
    this.setData((old) => ({ ...old, dataState: DataState.LOADED }));
    return this;
  }

  softReset(): AtomDataClass<T> {
    this.setData((old) => ({ ...old, dataState: DataState.NOT_LOADED }));
    return this;
  }
}

export const useData = <T>(atom: RecoilState<BaseAtomDataI<T>>) => {
  const [data, setData] = useRecoilState(atom);

  return useMemo(() => {
    return new AtomDataClass<T>(data, setData);
  }, [data, setData]);
};

export const createDataAtom = <T>(
  key: string,
  defaultData: T | null = null,
) => {
  return atom<BaseAtomDataI<T>>({
    key,
    default: {
      dataState: DataState.NOT_LOADED,
      model: defaultData,
    },
  });
};
