import React, { useState } from "react";
import { MarkdownCitation } from "../markdown/MarkdownCitation";

export const AdminMarkdownTester = () => {
  const [text, setText] = useState("");

  return (
    <div>
      <h2>Markdown Tester</h2>
      <textarea
        onChange={(e) => setText(e.target.value)}
        value={text}
        placeholder="Type..."
      ></textarea>
      <MarkdownCitation markdown={text} />
    </div>
  );
};
