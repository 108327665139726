import { Rect } from "../../../model/blueprintServer";
import { calculateLineThickness } from "../util/canvasSizeUtil";

export const flashRectangleBorders =
  (rect: Rect) => (ctx: CanvasRenderingContext2D) => {
    const { start, end } = rect;
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const minX = Math.min(start.x, end.x);
    const minY = Math.min(start.y, end.y);
    const w = Math.max(start.x, end.x) - minX;
    const h = Math.max(start.y, end.y) - minY;

    const clearRect = () => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    };
    const drawRectangleBorders = (color: string) => {
      ctx.strokeStyle = color; // Flash color for borders
      ctx.lineWidth = calculateLineThickness(ctx);
      ctx.strokeRect(x, y, w, h);
    };

    drawRectangleBorders("red");
    setTimeout(() => {
      clearRect();
    }, 1000);
  };
