import React, { useRef, useState } from "react";
import { MarkdownCitation } from "../markdown/MarkdownCitation";
import classNames from "classnames";
import { getReportResultClass } from "./reportUtil";
import { SectionComplianceReport } from "../../model/blueprintServer";
import { codeSymbol, getCodeTypeText } from "../../util/codeUtil";
import { Button, ButtonColor, ButtonSize } from "../theme/Button";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import { CLICK_ON_PLAN_ANNOTATION_EVENT } from "../sideBySide/PageCanvas";

interface SectionCardProps {
  item: SectionComplianceReport;
}

export const SectionComplianceReportCard = ({ item }: SectionCardProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const [expandCode, setExpendCode] = useState(false);
  const [expandAnalysis, setExpandAnalysis] = useState(false);
  const [isSelected, setSelected] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useBrowserEventListenerEvent(CLICK_ON_PLAN_ANNOTATION_EVENT, ({ detail }) => {
    const sectionComplianceId = detail as string;
    if (item.sectionComplianceId === sectionComplianceId) {
      setExpandedCard(true);
      setSelected(true);
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setExpandedCard(false);
      setSelected(false);
    }
  });

  return (
    <div
      className={classNames("card", {
        expanded: expandedCard,
        selected: isSelected,
      })}
      onMouseLeave={() => setSelected(false)}
      ref={ref}
    >
      <div
        className="flex card-header"
        onClick={() => setExpandedCard((old) => !old)}
      >
        <div className="code">
          <span
            className={classNames(
              "title",
              getReportResultClass(item.finalAssessment),
            )}
          >
            {codeSymbol(item.buildingCode)} {item.buildingCode.fullSectionPath}{" "}
            @ {item.buildingCode.year}
          </span>
          <span className={classNames("code-type", { show: expandedCard })}>
            {getCodeTypeText(item.buildingCode.type, true)}
          </span>
        </div>

        <div className="chapter-title">{item.buildingCode.title}</div>
      </div>
      {expandedCard && (
        <div className="content">
          {!expandCode && (
            <div
              className="show-code-hover"
              onClick={() => setExpendCode((old) => !old)}
            >
              Show code
            </div>
          )}
          {expandCode && (
            <div>
              <div className="sub-title flex">
                {expandCode && <span>Code ({item.buildingCode.year})</span>}
                {expandCode && (
                  <Button
                    color={ButtonColor.TRANSPARENT}
                    onClick={() => setExpendCode(false)}
                  >
                    Hide
                  </Button>
                )}
              </div>
              <div className={classNames({ expend: expandCode })}>
                {expandCode && (
                  <div className="code">
                    <MarkdownCitation markdown={item.buildingCode.text} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="reason">
            <h4>Summary</h4>
            <MarkdownCitation markdown={item.analysisSummary} />
          </div>
          <div className="reason">
            <h4>
              Analysis
              <Button
                size={ButtonSize.SLIM}
                color={ButtonColor.DARK}
                onClick={() => setExpandAnalysis((old) => !old)}
              >
                {expandAnalysis ? "Hide" : "Show more"}
              </Button>
            </h4>
            {expandAnalysis && <MarkdownCitation markdown={item.analysis} />}
          </div>
        </div>
      )}
    </div>
  );
};
