import { useRecoilValue } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import { SectionRef } from "./useFetchBuildingCode";

export const useGetBuildingCode = () => {
  const { codesByBookId, codesByCityAndYear } =
    useRecoilValue(buildingCodeAtom);
  return (sectionRef: SectionRef) => {
    const { cityName, codeYear, bookId, fullSectionPath } = sectionRef;
    if (cityName && codeYear) {
      return codesByCityAndYear
        .get(cityName)
        ?.get(codeYear)
        ?.get(fullSectionPath);
    }

    if (bookId) {
      return codesByBookId.get(bookId)?.get(fullSectionPath);
    }
  };
};
