import { PlanViewer } from "../planManagment/PlanViewer";
import "./planViewerPage.scss";

export const PlanViewerPage = () => (
  <div className="plan-viewer-page">
    <PlanViewer
      getCanvasAnnotations={() => []}
      enableAnnotationTooltip={false}
    />
  </div>
);
