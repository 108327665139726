import { BaseApi } from "./BaseApi";
import { ComponentData, PlanModel, PlanQA } from "../model/blueprintServer";

class AdminApi extends BaseApi {
  constructor() {
    super("admin");
  }

  markComplete(planId: string) {
    return this.get(`mark-complete?planId=${planId}`);
  }

  copyAnnotations(planId: string, planQaId: string) {
    return this.get(
      `inject-page-components?planId=${planId}&planQaForAnnotationId=${planQaId}`,
    );
  }

  planPages(planId: string): Promise<PlanModel> {
    return this.get(`get-pages?planId=${planId}`);
  }

  async getPlanQa(planQaId: string): Promise<PlanQA[]> {
    return this.get(`get-plan-qa?planQaId=${planQaId}`);
  }

  async updateComponentText(
    planId: string,
    pageNumber: number,
    componentId: string,
    componentData: ComponentData,
  ): Promise<PlanQA[]> {
    return this.post(
      `update-page-component?planId=${planId}&pageNumber=${pageNumber}&componentId=${componentId}`,
      componentData,
    );
  }

  async createSubComponent(
    planId: string,
    pageIndex: number,
    parentComponentId: string,
  ) {
    return this.get(
      `create-sub-components?parentComponentId=${parentComponentId}&planId=${planId}&pageIndex=${pageIndex}`,
    );
  }
}

export const adminApi = new AdminApi();
