import { ReviewResultTypeFilter } from "../reportSectionFilter/ReviewResultTypeFilter";
import { Checkbox } from "../../checkbox/Checkbox";
import { Tooltip } from "react-tooltip";
import "./reportFiltersTooltip.scss";
import { useRecoilState } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";

export const REPORTS_FILTER_TOOLTIP_KEY = "report-filters-tooltip";

export const ReportFiltersTooltip = () => {
  const [{ filterByPage }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );
  const handleChangeFilterByPageIndex = () => {
    setPlanReportFilters((old) => ({
      ...old,
      filterByPage: !old.filterByPage,
    }));
  };
  return (
    <Tooltip
      className="report-filters"
      id={REPORTS_FILTER_TOOLTIP_KEY}
      place="bottom"
      clickable
    >
      <h3>Filters</h3>
      <div className="inner flex">
        <div>
          <h4>Compliance type</h4>
          <ReviewResultTypeFilter />
        </div>
        <div>
          <h4>Sheet options</h4>
          <Checkbox
            checked={filterByPage}
            value="Filter per sheet"
            handleClick={handleChangeFilterByPageIndex}
          />
        </div>
      </div>
    </Tooltip>
  );
};
