import React from "react";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "./processingOrReadyChip.scss";
import { SpinnerLoader } from "../../other/SpinnerLoader";

interface ProcessingOrReadyChipProps {
  isProcessingPlan: boolean;
}

export const ProcessingOrReadyChip = ({
  isProcessingPlan,
}: ProcessingOrReadyChipProps) => (
  <div
    className={classNames("processingOrReadyChip flex", {
      processing: isProcessingPlan,
    })}
    data-tooltip-id="processing"
  >
    <span>{isProcessingPlan ? <SpinnerLoader /> : <FaCheck />}</span>
    {isProcessingPlan && (
      <Tooltip id="processing">
        It takes up to 24 hours to process a plan
      </Tooltip>
    )}
  </div>
);
