import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ComponentData, PlanModel } from "../../model/blueprintServer";
import { ModalContentComponent } from "../theme/useModal";
import { adminApi } from "../../api/adminApi";
import "./adminUpdateComponentData.scss";
import { formatImageUrl } from "../../util/imageUtil";
import { useRecoilState } from "recoil";
import { adminPlanAtom, sortComponentsForAdmin } from "./AdminPlanUI";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";

export interface AdminUpdateComponentDataData {
  planId: string;
  pageNumber: number;
  initialComponentIndex: number;
  plan: PlanModel;
}

const AdminUpdateComponentData = ({
  data,
  handleClose,
}: ModalContentComponent<AdminUpdateComponentDataData>) => {
  if (!data) {
    return null;
  }

  return <AdminUpdateComponentDataInner {...data} handleClose={handleClose} />;
};

interface AdminUpdateComponentDataInnerProps
  extends AdminUpdateComponentDataData {
  handleClose: () => void;
}

const AdminUpdateComponentDataInner = ({
  initialComponentIndex,
  pageNumber,
  planId,
  handleClose,
}: AdminUpdateComponentDataInnerProps) => {
  const [localPlan, setLocalPlan] = useRecoilState(adminPlanAtom);
  const [currentComponentIndex, setCurrentComponentIndex] = useState(
    initialComponentIndex,
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber);

  const components = useMemo(
    () => localPlan.pages[currentPageIndex].components,
    [localPlan, currentPageIndex],
  );

  const sortedComponents = useMemo(
    () => sortComponentsForAdmin(components),
    [components],
  );

  const currentComponent = useMemo(
    () => sortedComponents[currentComponentIndex],
    [sortedComponents, currentComponentIndex],
  );

  const parentChild = useMemo(
    () => (currentComponent?.parentComponentId ? "Child" : "Parent"),
    [currentComponent],
  );

  const componentData = useMemo(
    () => currentComponent.data,
    [currentComponent],
  );

  const [title, setTitle] = useState(componentData?.title ?? "");
  const [description, setDescription] = useState(
    componentData?.description ?? "",
  );
  const [extractedData, setExtractedData] = useState(
    componentData?.extractedData ?? "",
  );
  const [categories, setCategories] = useState(
    componentData?.categories.join(", ") ?? "",
  );

  const imageURL = useMemo(
    () =>
      sortedComponents?.length
        ? formatImageUrl(
            sortedComponents[currentComponentIndex].imageBlobStorage,
          )
        : "",
    [sortedComponents, currentComponentIndex],
  );

  const updateLocalComponent = useCallback(
    (
      pageIndex: number,
      componentIndex: number,
      newComponentData: ComponentData,
    ) => {
      if (!localPlan) {
        return;
      }

      const newPages = localPlan.pages.map((page, pIndex) => {
        if (pIndex === pageIndex) {
          const newComponents = page.components.map((component, compIndex) => {
            if (compIndex === componentIndex) {
              return { ...component, data: { ...newComponentData } };
            }
            return { ...component };
          });
          return { ...page, components: newComponents };
        }
        return { ...page };
      });

      const newPlan: PlanModel = { ...localPlan, pages: newPages };
      setLocalPlan(newPlan);
    },
    [localPlan, setLocalPlan],
  );

  const updateComponentDataLocal = useCallback(() => {
    if (!componentData) {
      return;
    }
    const newComp: ComponentData = {
      ...componentData,
      title,
      description,
      extractedData,
      categories: categories.split(",").map((cat) => cat.trim()),
    };

    updateLocalComponent(currentPageIndex, currentComponentIndex, newComp);
  }, [
    componentData,
    title,
    description,
    extractedData,
    categories,
    currentPageIndex,
    currentComponentIndex,
    updateLocalComponent,
  ]);

  useEffect(() => {
    setTitle(componentData?.title ?? "");
    setDescription(componentData?.description ?? "");
    setExtractedData(componentData?.extractedData ?? "");
    setCategories(componentData?.categories.join(", ") ?? "");
  }, [componentData]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleUpdate = useCallback(async () => {
    if (!componentData) {
      setError(null);
      setSuccess(false);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    const updatedComponentData: ComponentData = {
      title,
      description,
      extractedData,
      categories: categories.split(",").map((cat) => cat.trim()),
    };

    try {
      await adminApi.updateComponentText(
        planId,
        currentPageIndex,
        currentComponent.componentId,
        updatedComponentData,
      );
      setSuccess(true);
      updateComponentDataLocal();
    } catch (err) {
      setError("Failed to update component data");
    } finally {
      setLoading(false);
    }
  }, [
    componentData,
    title,
    description,
    extractedData,
    categories,
    planId,
    updateComponentDataLocal,
    currentComponent.componentId,
    currentPageIndex,
  ]);

  const handleSaveAndClose = useCallback(() => {
    handleUpdate();
    handleClose();
  }, [handleUpdate, handleClose]);

  const handlePrevCompAndSave = useCallback(() => {
    handleUpdate();
    setCurrentComponentIndex((old) => Math.max(old - 1, 0));
  }, [handleUpdate]);

  const handleNextCompAndSave = useCallback(() => {
    handleUpdate();
    setCurrentComponentIndex((old) =>
      Math.min(old + 1, sortedComponents.length - 1),
    );
  }, [handleUpdate, sortedComponents.length]);

  const handlePrevPageAndSave = useCallback(() => {
    handleUpdate();
    setCurrentComponentIndex(0);
    setCurrentPageIndex((old) => Math.max(old - 1, 0));
  }, [handleUpdate]);

  const handleNextPageAndSave = useCallback(() => {
    handleUpdate();
    setCurrentComponentIndex(0);
    setCurrentPageIndex((old) => Math.min(old + 1, localPlan.pages.length - 1));
  }, [handleUpdate, localPlan.pages.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (
        activeElement instanceof HTMLElement &&
        (activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA")
      ) {
        return;
      }

      if (event.key === "ArrowLeft") {
        handlePrevCompAndSave();
      } else if (event.key === "ArrowRight") {
        handleNextCompAndSave();
      }
    },
    [handlePrevCompAndSave, handleNextCompAndSave],
  );

  useBrowserEventListenerEvent("keydown", handleKeyDown);

  return (
    <div className="admin-update-component-data">
      <div className="buttons-bar">
        <div className="save-buttons">
          <button onClick={handleUpdate}>Save</button>
          <button onClick={handleSaveAndClose}>Save and close</button>
        </div>
        <div className="counter">
          <div>
            <div>Page: {currentPageIndex + 1}</div>
            <div>
              Component: {currentComponentIndex + 1} / {sortedComponents.length}
            </div>
            <div>
              {loading && <span style={{ color: "green" }}>loading</span>}
              {error && <span style={{ color: "red" }}>error</span>}
              {success && <span style={{ color: "blue" }}>saved</span>}
            </div>
          </div>
          <div>Hint: use keyboard arrows, it saves</div>
        </div>
        <div className="navigation-buttons">
          <div className="buttons-set">
            <button onClick={handlePrevCompAndSave}>Prev component</button>
            <button onClick={handleNextCompAndSave}>Next component</button>
          </div>
          <div className="buttons-set">
            <button onClick={handlePrevPageAndSave}>Prev page</button>
            <button onClick={handleNextPageAndSave}>Next page</button>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="image-container">
          <img
            src={imageURL}
            alt="Component Preview"
            className="component-image"
          />
        </div>

        <div className="text-container">
          <h2>{parentChild}</h2>
          <label>
            Title
            <textarea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>
          <label>
            Description (Markdown Preview Below):
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="markdown-preview">
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
          </label>

          <label>
            Extracted Data (Markdown Preview Below):
            <textarea
              value={extractedData}
              onChange={(e) => setExtractedData(e.target.value)}
            />
            <div className="markdown-preview">
              <ReactMarkdown>{extractedData}</ReactMarkdown>
            </div>
          </label>

          <label>
            Categories (comma-separated):
            <textarea
              value={categories}
              onChange={(e) => setCategories(e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AdminUpdateComponentData;
