import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { DataState, projectsAtom } from "../../../atom/projectsAtom";
import { projectApi } from "../../../api/projectApi";
import "./createProject.scss";
import { generatePath, useNavigate } from "react-router-dom";
import { AddressForm } from "../../other/AddressForm";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { ModalContentComponent } from "../../theme/useModal";
import { CityName } from "../../../model/blueprintServer";

export const CreateProject = ({ handleClose }: ModalContentComponent) => {
  const setProjectStoreAtom = useSetRecoilState(projectsAtom);
  const [error, setError] = useState<String | null>(null);
  const navigate = useNavigate();

  const handleSubmit = (placeId: string) => {
    setProjectStoreAtom((old) => ({
      ...old,
      state: DataState.LOADING,
    }));
    projectApi
      .create(placeId)
      .then((project) => {
        setProjectStoreAtom((old) => ({
          state: DataState.LOADED,
          list: [...old.list, project],
        }));
        handleClose();
        if (project.jurisdiction.cityName === CityName.NOT_DETERMINED) {
          generatePath("/" + ROUTER_PATHS.ROOT);
        } else {
          navigate(
            generatePath("/" + ROUTER_PATHS.PROJECT_SETTINGS, {
              projectId: project.projectId,
            }),
          );
        }
      })
      .catch(() => {
        setError("Failed to create address");
        setProjectStoreAtom((old) => ({
          ...old,
          state: DataState.LOADED,
        }));
      });
  };

  return (
    <div className="create-project">
      <h3>Create project</h3>
      {error ? <div className="error">{error}</div> : ""}
      <div className="info">
        <p>
          <strong>Blueplan</strong> is currently operating in the five boroughs
          of New York and in the Philadelphia area.
        </p>
        <p>We are quick to add additional locations upon request.</p>
      </div>
      <div className="input-container">
        <div className="label">Search address</div>
        <AddressForm handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};
