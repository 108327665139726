import React from "react";
import "./sideBySideContentForReport.scss";
import { ReportListJourney } from "../planReport/reportListJourney/ReportListJourney";

export const SideBySideContentForReport = () => {
  return (
    <div className="right-hand-size-for-report flex">
      <div className="selected-tab">
        <ReportListJourney />
      </div>
    </div>
  );
};
