import React, { useCallback, useEffect, useState } from "react";
import { PlanQuestion } from "../../../model/blueprintServer";
import { SideBySideView } from "../../sideBySide/SideBySideView";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { SideBySideContentForPlanQa } from "../../sideBySide/SideBySideContentForPlanQa";
import { appMenuStateAtom } from "../../../atom/MenuStateAtom";
import { usePlanQaQuestions } from "../../../atom/questionAtom";
import { useAllPlanComponents } from "../hook/useAllPlanComponents";
import {
  CanvasAnnotation,
  componentToCanvasAnnotation,
} from "../../canvas/util/canvasAnnotationUtil";
import { useBrowserEventListenerEvent } from "../../../hooks/useBrowserEventListenerEvent";
import {
  RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
  SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
  ShowPageInPageView,
} from "../../markdown/PageRefLink";

export const PlanQaPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planQaQuestions = usePlanQaQuestions();
  const setAppMenuState = useSetRecoilState(appMenuStateAtom);
  const [collapseAppSidebar, setCollapseAppSidebar] = useState(false);
  const allComponents = useAllPlanComponents();
  const [selectedAnnotation, setSelectedAnnotation] = useState("");

  const handleShowAnnotation = (event: any) => {
    const { componentId } = event.detail as ShowPageInPageView;

    if (!componentId) {
      return;
    }

    setSelectedAnnotation(componentId);
  };

  useBrowserEventListenerEvent(
    SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
    handleShowAnnotation,
  );

  useBrowserEventListenerEvent(RESET_COMPONENT_IN_PAGE_INDEX_EVENT, () =>
    setSelectedAnnotation(""),
  );

  useEffect(() => {
    if (!collapseAppSidebar) {
      setAppMenuState((old) => ({ ...old, collapse: true }));
      setCollapseAppSidebar(true);
    }
  }, [collapseAppSidebar, setAppMenuState, setCollapseAppSidebar]);

  // Returns the one selected canvas annotation.
  const getCanvasAnnotations = useCallback((): CanvasAnnotation[] => {
    if (planQaQuestions.isNotDone()) {
      return [];
    }

    const annotations = planQaQuestions
      .data()
      .map((question) => question.answer?.markedBlueprintLocation)
      .flat()
      .filter((component) => component?.componentId === selectedAnnotation)
      .map((componentReference) => {
        if (!componentReference) {
          return null;
        }
        const { componentId } = componentReference;
        const component = allComponents[componentId];
        if (!component) {
          return null;
        }

        return componentToCanvasAnnotation(
          component,
          component.pageIndex ?? -1,
        );
      });
    return annotations.filter((a) => a !== null) as CanvasAnnotation[];
  }, [allComponents, planQaQuestions, selectedAnnotation]);

  if (!currentProject.model || planQaQuestions.isNotDone()) {
    return null;
  }

  return (
    <div>
      <SideBySideView<PlanQuestion>
        items={planQaQuestions.data()}
        cityName={currentProject.model.jurisdiction.cityName}
        RightHandSide={SideBySideContentForPlanQa}
        title="Q&A"
        getCanvasAnnotations={getCanvasAnnotations}
        enableAnnotationTooltip={false}
      />
    </div>
  );
};
