import React, { useCallback, useMemo, useRef, useState } from "react";
import { Painter, runPainter } from "./painter/painterUtil";
import { Size } from "../../model/math";
import "./canvas.scss";
import { getImageMeta } from "./util/canvasImageUtil";
import { useCanvasSizeAndScale } from "./useCanvasSizeAndScale";

export const useCanvasBase = (
  id: string,
  handleMouseDown?: (event: React.MouseEvent | React.TouchEvent) => void,
) => {
  const [size, setSize] = useState<Size | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasSizeInfo = useCanvasSizeAndScale(size, setSize, canvasRef);
  const { scale } = canvasSizeInfo;

  const drawFrame = useCallback(
    (painters: Painter[], imageUrl?: string) => {
      let canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      if (!size && imageUrl) {
        getImageMeta(imageUrl, (imgEle) => {
          setSize({ width: imgEle.naturalWidth, height: imgEle.naturalHeight });
        });
        return;
      }

      for (const painter of painters) {
        ctx.scale(scale, scale);
        runPainter(ctx, painter);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    },
    [canvasRef, scale, size, setSize],
  );

  const Canvas = useMemo(
    () => (
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        id={`canvas-layer-${id}`}
      ></canvas>
    ),
    [canvasRef, handleMouseDown, id],
  );
  return { Canvas, canvasRef, drawFrame, canvasSizeInfo };
};
