import React from "react";
import { Button } from "../theme/Button";

interface CreateSubComponentButtonProps {
  planId: string;
  pageNumber: number;
  componentId: string;
}

export const CreateSubComponentButton = ({
  planId,
  pageNumber,
  componentId,
}: CreateSubComponentButtonProps) => {
  const copySubCompId = () => {
    navigator.clipboard.writeText(componentId);
  };
  return (
    <Button onClick={copySubCompId}>
      Copy component id for page {pageNumber}
    </Button>
  );
};
