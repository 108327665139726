import { atom } from "recoil";
import { ReviewResultType } from "../../../model/blueprintServer";
import { ReviewResultTypeCategories } from "../reportSectionFilter/ReviewResultTypeFilter";

export interface PlanReportFiltersAtom {
  searchText: string;
  reviewResultTypeFilter: ReviewResultTypeCategories;
  pageIndex: number;
  filterByPage: boolean;
}

export const planReportFiltersAtom = atom<PlanReportFiltersAtom>({
  key: "PlanReportFiltersAtom",
  default: {
    searchText: "",
    pageIndex: 0,
    filterByPage: false,
    reviewResultTypeFilter: {
      [ReviewResultType.COMPLIANCE]: false,
      [ReviewResultType.NON_COMPLIANCE]: true,
      [ReviewResultType.ON_SITE_VERIFICATION]: true,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION]: false,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS]: false,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO]:
        false,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO]: false,
      [ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS]:
        false,
      [ReviewResultType.NO_ENOUGH_DATA_OTHER]: false,
      [ReviewResultType.DEEPER_REVIEW]: true,
      [ReviewResultType.NOT_APPLICABLE]: false,
    },
  },
});
