import { CityName } from "../../../model/blueprintServer";
import { useRecoilState } from "recoil";
import {
  buildingCodeAtom,
  BuildingCodeSectionsAtom,
} from "../../../atom/buildingCodeAtom";
import { buildingCodeApi } from "../../../api/buildingCodeApi";
import { Dispatch, SetStateAction, useCallback } from "react";

interface SectionGroup {
  cityName: CityName;
  year: number;
  sections: string[];
}

export const fetchForCityAndYear = async (
  cityName: CityName,
  year: number,
  sections: string[],
  buildingCodeCache: BuildingCodeSectionsAtom,
  setBuildingCodeCache: Dispatch<SetStateAction<BuildingCodeSectionsAtom>>,
): Promise<void> => {
  const cityMap =
    buildingCodeCache.codesByCityAndYear.get(cityName) || new Map();
  const yearMap = cityMap.get(year) || new Map();

  const missingSections = sections.filter((section) => !yearMap.has(section));
  if (missingSections.length === 0) {
    return;
  }

  const data = await buildingCodeApi.getCodesForCityAndYear(
    cityName,
    year,
    missingSections,
  );
  const updatedYearMap = new Map(yearMap);
  data.forEach((code) => updatedYearMap.set(code.fullSectionPath, code));

  const updatedCityMap = new Map(cityMap);
  updatedCityMap.set(year, updatedYearMap);

  setBuildingCodeCache((prev) => ({
    ...prev,
    codesByCityAndYear: new Map(prev.codesByCityAndYear).set(
      cityName,
      updatedCityMap,
    ),
  }));
};

export const fetchForBookId = async (
  bookId: string,
  sections: string[],
  buildingCodeCache: BuildingCodeSectionsAtom,
  setBuildingCodeCache: Dispatch<SetStateAction<BuildingCodeSectionsAtom>>,
): Promise<void> => {
  const bookMap = buildingCodeCache.codesByBookId.get(bookId) || new Map();
  const alreadyLoaded =
    buildingCodeCache.alreadyLoaded.get(bookId) || new Set();
  const missingSections = sections.filter(
    (section) => !bookMap.has(section) && !alreadyLoaded.has(section),
  );
  if (missingSections.length === 0) {
    return;
  }

  missingSections.forEach((section) => alreadyLoaded.add(section));
  // Set the already loaded so we don't have to re-fetch them.
  setBuildingCodeCache((prev) => ({
    ...prev,
    alreadyLoaded: new Map(prev.alreadyLoaded).set(bookId, alreadyLoaded),
  }));

  const data = await buildingCodeApi.getCodesForBookId(bookId, missingSections);
  const updatedBookMap = new Map(bookMap);
  data.forEach((code) => updatedBookMap.set(code.fullSectionPath, code));

  setBuildingCodeCache((prev) => ({
    ...prev,
    codesByBookId: new Map(prev.codesByBookId).set(bookId, updatedBookMap),
  }));
};

export const groupSectionsByType = (
  sectionsRef: SectionRef[],
  cityYearGroups: Map<string, SectionGroup>,
  bookIdGroups: Map<string, string[]>,
): void => {
  sectionsRef.forEach(({ bookId, cityName, codeYear, fullSectionPath }) => {
    if (bookId) {
      if (!bookIdGroups.has(bookId)) {
        bookIdGroups.set(bookId, []);
      }
      bookIdGroups.get(bookId)?.push(fullSectionPath);
    } else if (cityName && codeYear) {
      const key = `${cityName}-${codeYear}`;
      if (!cityYearGroups.has(key)) {
        cityYearGroups.set(key, { cityName, year: codeYear, sections: [] });
      }
      cityYearGroups.get(key)?.sections.push(fullSectionPath);
    }
  });
};

export interface SectionRef {
  bookId: string | undefined;
  cityName: CityName | undefined;
  codeYear: number | undefined;
  fullSectionPath: string;
}

export const useFetchBuildingCode = () => {
  const [buildingCodeCache, setBuildingCodeCache] =
    useRecoilState(buildingCodeAtom);

  return useCallback(
    (sectionsRef: SectionRef[]) => {
      const cityYearGroups = new Map<string, SectionGroup>();
      const bookIdGroups = new Map<string, string[]>();

      groupSectionsByType(sectionsRef, cityYearGroups, bookIdGroups);

      Promise.all([
        ...Array.from(cityYearGroups.values()).map(
          ({ cityName, year, sections }) =>
            fetchForCityAndYear(
              cityName,
              year,
              sections,
              buildingCodeCache,
              setBuildingCodeCache,
            ),
        ),
        ...Array.from(bookIdGroups.entries()).map(([bookId, sections]) =>
          fetchForBookId(
            bookId,
            sections,
            buildingCodeCache,
            setBuildingCodeCache,
          ),
        ),
      ]);
    },
    [buildingCodeCache, setBuildingCodeCache],
  );
};
