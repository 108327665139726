import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  PlanPage,
  SectionComplianceReport,
  XYPoint,
} from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import "../planReport/pageView.scss";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { CanvasAnnotation } from "../canvas/util/canvasAnnotationUtil";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { Tooltip } from "react-tooltip";
import { filteredSectionComplianceReport } from "../planReport/atom/filteredPlanReportSectionsAtom";
import { SectionCanvasPicker } from "./SectionCanvasPicker";
import { RESET_COMPONENT_IN_PAGE_INDEX_EVENT } from "../markdown/PageRefLink";
import classNames from "classnames";
import { CanvasMenu } from "../canvas/CanvasMenu";
import { useCreateAllCanvasLayers } from "../canvas/useCreateAllCanvasLayers";

export const CLICK_ON_PLAN_ANNOTATION_EVENT = "click-on-plan-annotation-event";

interface PageViewProps {
  page: PlanPage;
  handleFinishLoadImage: () => void;
  isLoadingImage: boolean;
  filteredAnnotations: CanvasAnnotation[];
  setPageIndex: (page: number) => void;
  enableAnnotationTooltip: boolean;
}

export const PageCanvas = ({
  page,
  handleFinishLoadImage,
  isLoadingImage,
  filteredAnnotations,
  setPageIndex,
  enableAnnotationTooltip = false,
}: PageViewProps) => {
  const { adjustedContainerWidth, adjustedContainerHeight, zoom } =
    useRecoilValue(pageImageSizeAtom);
  const clickOnPlanAnnotation = useFireBrowserEvent(
    CLICK_ON_PLAN_ANNOTATION_EVENT,
  );
  const [tooltipPosition, setTooltipPosition] = useState<XYPoint | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const sections = useRecoilValue(filteredSectionComplianceReport);
  const [relevantSections, setRelevantSections] = useState<
    SectionComplianceReport[]
  >([]);
  const zoomContainerRef = useRef<HTMLDivElement | null>(null);

  const handlePageUp = useCallback(() => {
    if (!page) {
      return;
    }

    setPageIndex(page.pageNumber - 1);
  }, [setPageIndex, page]);

  const handlePageDown = useCallback(() => {
    if (!page) {
      return;
    }

    setPageIndex(page.pageNumber + 1);
  }, [setPageIndex, page]);

  const handleClickOnAnnotation = (
    annotationKey: string | null,
    point: XYPoint | null,
  ) => {
    if (!enableAnnotationTooltip) {
      return;
    }

    if (point && annotationKey) {
      setShowTooltip(true);
      setTooltipPosition(point);
    } else {
      closeTooltip();
    }

    if (!annotationKey) {
      return;
    }
    const annotation = filteredAnnotations.find(
      (c) => c.componentId === annotationKey,
    );
    if (!annotation) {
      return;
    }

    // Find all the sections that are relevant to the component.
    const relevantSections = sections.filter((section) =>
      section.markedBlueprintLocation.find(
        (c) => c.componentId === annotation.componentId,
      ),
    );
    if (relevantSections.length === 1) {
      clickOnPlanAnnotation(relevantSections[0].sectionComplianceId);
    }
    setRelevantSections(relevantSections);
  };

  const CanvasLayers = useCreateAllCanvasLayers(
    page,
    filteredAnnotations,
    () => {},
    handleClickOnAnnotation,
    isLoadingImage,
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      handlePageUp();
    } else if (event.key === "ArrowDown") {
      handlePageDown();
    }
  };

  const closeTooltip = () => {
    setShowTooltip(false);
    setTooltipPosition(null);
  };

  useBrowserEventListenerEvent(
    RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
    closeTooltip,
  );
  useBrowserEventListenerEvent("keydown", handleKeyDown);

  const imageLoader = useMemo(
    () =>
      isLoadingImage && (
        <div
          className="loader"
          style={{
            width: adjustedContainerWidth,
            height: adjustedContainerHeight,
          }}
        >
          <AppLogoLoader />
        </div>
      ),
    [isLoadingImage, adjustedContainerHeight, adjustedContainerWidth],
  );

  const zoomStyle = useMemo(() => {
    const ration = adjustedContainerWidth / adjustedContainerHeight;
    const top = zoom === 1 ? 0 : adjustedContainerHeight * 0.1 * zoom;
    const left = zoom === 1 ? 0 : ration * top;
    return { transform: `scale(${zoom})`, top, left };
  }, [zoom, adjustedContainerHeight, adjustedContainerWidth]);

  return (
    <div className="page-view-and-canvas">
      <Tooltip
        anchorSelect="body"
        id="select-section-tooltip"
        isOpen={showTooltip}
        closeOnEsc={true}
        position={tooltipPosition || { x: -100, y: -100 }}
        clickable
      >
        <SectionCanvasPicker
          relevantSections={relevantSections}
          close={closeTooltip}
        />
      </Tooltip>
      <CanvasMenu />
      <div className="canvas-wrapper">
        <div className="canvas" ref={zoomContainerRef} style={zoomStyle}>
          {imageLoader}
          <img
            className="under-image"
            src={formatImageUrl(page.pageImageBlob)}
            onLoad={handleFinishLoadImage}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          <div
            className={classNames({ "loading-under-image": isLoadingImage })}
          >
            {CanvasLayers}
          </div>
        </div>
      </div>
    </div>
  );
};
