import { useEffect, useState } from "react";
import { useBookMetadataData } from "../../../atom/bookMetadataAtom";
import { projectApi } from "../../../api/projectApi";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";

export const useGetJurisdictionBookMapping = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const jurisdictionToBooksData = useBookMetadataData();
  const [lastProjectId, setLastProjectId] = useState("");

  useEffect(() => {
    if (!currentProject.model) {
      return;
    }

    const projectId = currentProject.model.projectId;
    if (lastProjectId === projectId) {
      return;
    }

    setLastProjectId(projectId);
    projectApi
      .getJurisdictionBookMapping(currentProject.model.jurisdiction.cityName)
      .then((mapping) => {
        jurisdictionToBooksData.update(mapping);
        jurisdictionToBooksData.endLoading();
      });
  }, [currentProject.model, jurisdictionToBooksData, lastProjectId]);
};
