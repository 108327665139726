import React, { useMemo, useRef, useState } from "react";
import { niceDate } from "../../../util/dateUtil";
import { MarkdownCitation } from "../../markdown/MarkdownCitation";
import {
  DataLoadingStates,
  PlanQuestion,
  ShortBuildingCodeRecord,
} from "../../../model/blueprintServer";
import { BuildingCodeCard } from "./BuildingCodeCard";
import "./qaAnswer.scss";
import { Button, ButtonColor, ButtonSize } from "../../theme/Button";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { LinearLoader } from "../../other/LinearLoader";
import { usePollPlanQuestionStatus } from "./usePollPlanQuestionStatus";
import { ResourceAvatar } from "../../other/ResourceAvatar";
import { useMaybeGetBookMetadata } from "../hook/useMaybeGetBookMetadata";
import { useGetBuildingCode } from "../hook/useGetBuildingCode";
import { SectionRef } from "../hook/useFetchBuildingCode";

export const QAAnswer = ({
  created,
  question,
  answer,
  booksMetadata,
  status,
  id,
}: PlanQuestion) => {
  const [showContent, setShowContent] = useState(false);
  usePollPlanQuestionStatus(id);

  const details = useMemo(() => {
    if (!showContent) {
      return null;
    }

    return (
      <div>
        <div className="sources-container">
          {/*<Button classes="navigation prev" color={ButtonColor.ICON}><FaArrowLeft /></Button>*/}
          <div className="sources flex">
            {answer?.relevantSections.map((source, index) => (
              <Source source={source} key={index} />
            ))}
          </div>
          {/*<Button classes="navigation next" color={ButtonColor.ICON}><FaArrowRight /></Button>*/}
        </div>
        <div>
          <h4>Analysis</h4>
          <MarkdownCitation markdown={answer?.analysis} />
        </div>
      </div>
    );
  }, [showContent, answer]);

  const content = useMemo(() => {
    if (status !== DataLoadingStates.COMPLETED) {
      return (
        <div className="thinking">
          <LinearLoader />
        </div>
      );
    }

    return (
      <>
        <div className="answer">
          <div>
            <MarkdownCitation markdown={answer?.answer} />
            <MarkdownCitation markdown={answer?.analysisSummary} />
          </div>
        </div>
        <div>
          <Button
            size={ButtonSize.SMALL}
            color={ButtonColor.BRAND}
            onClick={() => setShowContent((old) => !old)}
            classes="expend"
          >
            {showContent ? "Show less" : "Show more"}
          </Button>
        </div>
        {details}
      </>
    );
  }, [status, details, showContent, setShowContent, answer]);

  return (
    <div className={classNames("qa-answer", { "is-open": showContent })}>
      <div className="header flex">
        <span>{niceDate(created)}</span>
      </div>
      <div className="question flex">
        <ResourceAvatar resourceId={id} />
        <div className="question-text">{question}</div>
      </div>
      {content}
    </div>
  );
};

interface SourceProps {
  source: ShortBuildingCodeRecord;
}

const Source = ({ source }: SourceProps) => {
  const book = useMaybeGetBookMetadata(source.bookId);
  const ref = useRef<HTMLDivElement | null>(null);

  const { sectionPath, bookId } = source;

  const getBuildingCode = useGetBuildingCode();
  const buildingCode = getBuildingCode({
    fullSectionPath: sectionPath,
    bookId,
  } as SectionRef);

  return (
    <div className="source" ref={ref} data-tooltip-id={source.sectionPath}>
      <div className="book">
        {book?.displayName} - {book?.year}
      </div>
      <div className="code-section">§{source.sectionPath}</div>
      <div className="source-title">{buildingCode?.title}</div>
      <Tooltip
        id={source.sectionPath}
        place="bottom-start"
        className="building-code-card"
        delayHide={0}
        clickable
      >
        <BuildingCodeCard
          fullSectionPath={source.sectionPath}
          bookId={source.bookId}
        />
      </Tooltip>
    </div>
  );
};
