import React, { useCallback, useMemo } from "react";
import { BookMetadata, BookType } from "../../../model/blueprintServer";
import { Dropdown } from "../../other/dropdown/Dropdown";
import "./codeAndBookSelector.scss";
import { Button, ButtonColor } from "../../theme/Button";
import { getCodeTypeText, groupBooksByType } from "../../../util/codeUtil";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { CityToBooks } from "../../../api/projectApi";
import { useRecoilState } from "recoil";
import { selectedCodesAtom } from "./QuestionBox";

interface CodeAndBookSelectorProps {
  books: CityToBooks;
}

export const CodeAndBookSelector = ({ books }: CodeAndBookSelectorProps) => {
  const [
    { bookType, bookGroup, selectedBookMetadata, selectedBooksMetadata },
    setSelectedCodes,
  ] = useRecoilState(selectedCodesAtom);
  const grouped = groupBooksByType(books);

  const isCurrentBookMetadataDuplicate = useMemo(() => {
    if (!selectedBookMetadata) {
      return false;
    }

    return selectedBooksMetadata.some((b) => b.id === selectedBookMetadata.id);
  }, [selectedBookMetadata, selectedBooksMetadata]);

  const availableYears = useMemo(() => {
    if (!bookType) {
      return [];
    }

    const items = grouped[bookType];
    if (!items) {
      return [];
    }

    return Object.values(items).map((book) => book);
  }, [grouped, bookType]);

  const addBook = useCallback(() => {
    if (!selectedBookMetadata || isCurrentBookMetadataDuplicate) {
      return;
    }

    setSelectedCodes((old) => ({
      ...old,
      bookType: null,
      bookGroup: null,
      year: null,
      selectedBookMetadata: null,
      selectedBooksMetadata: [
        ...old.selectedBooksMetadata,
        selectedBookMetadata,
      ],
    }));
  }, [setSelectedCodes, selectedBookMetadata, isCurrentBookMetadataDuplicate]);

  const booksValues: string[] = useMemo(() => {
    if (!bookType) {
      return [];
    }
    const groups = grouped[bookType];
    if (!groups) {
      return [];
    }
    return Object.keys(groups);
  }, [grouped, bookType]);

  const currentBooksMetadata: BookMetadata[] = useMemo(() => {
    if (!bookGroup || !bookType) {
      return [];
    }

    const groups = grouped[bookType];
    const booksMetadata = groups[bookGroup];
    if (!booksMetadata) {
      return [];
    }

    return booksMetadata;
  }, [grouped, bookType, bookGroup]);

  const updateBookType = useCallback(
    (bookType: BookType) => {
      setSelectedCodes((old) => ({
        ...old,
        bookType,
        bookGroup: null,
        selectedBookMetadata: null,
        year: null,
      }));
    },
    [setSelectedCodes],
  );

  const dropdowns = useMemo(
    () => (
      <div className="form">
        <Dropdown<BookType>
          value={bookType}
          values={Object.keys(grouped) as BookType[]}
          onChange={updateBookType}
          placeholder="Code type"
          valueFn={getCodeTypeText}
          closeOnClick={true}
        />
        <div className="flex second-row">
          <div className="flex group-and-year">
            <Dropdown<string>
              value={bookGroup}
              values={booksValues}
              onChange={(bookGroup) =>
                setSelectedCodes((old) => ({ ...old, bookGroup }))
              }
              disabled={!bookType || availableYears.length === 0}
              placeholder="Book"
              closeOnClick={true}
            />
            <Dropdown<BookMetadata>
              value={selectedBookMetadata}
              values={currentBooksMetadata}
              onChange={(selectedBookMetadata) =>
                setSelectedCodes((old) => ({ ...old, selectedBookMetadata }))
              }
              disabled={!bookType || availableYears.length === 0}
              placeholder="Year"
              valueFn={(b) => b?.year + "" ?? ""}
              classes="year-dropdown"
              closeOnClick={true}
              hasError={isCurrentBookMetadataDuplicate}
            />
          </div>
          <div className="flex buttons">
            <Button
              disabled={
                !selectedBookMetadata ||
                !bookType ||
                isCurrentBookMetadataDuplicate
              }
              onClick={addBook}
              classes="add-button"
              IconLeft={FaPlus}
            >
              Add
            </Button>
          </div>
        </div>
        {isCurrentBookMetadataDuplicate && (
          <div className="hint warning">Book already exist in the list</div>
        )}
      </div>
    ),
    [
      setSelectedCodes,
      selectedBookMetadata,
      bookType,
      addBook,
      availableYears,
      grouped,
      bookGroup,
      booksValues,
      currentBooksMetadata,
      updateBookType,
      isCurrentBookMetadataDuplicate,
    ],
  );

  const removeBookMetadata = useCallback(
    (index: number) => {
      const newSelectedBooksMetadata = [...selectedBooksMetadata];
      if (index > -1) {
        newSelectedBooksMetadata.splice(index, 1);
      }
      setSelectedCodes((old) => ({
        ...old,
        selectedBooksMetadata: newSelectedBooksMetadata,
      }));
    },
    [selectedBooksMetadata, setSelectedCodes],
  );

  const allBookMetadata = useMemo(() => {
    const items = selectedBooksMetadata.map((bookMetadata, index) => {
      return (
        <div className="book-ref-item">
          <Button
            color={ButtonColor.ICON}
            classes="remove"
            onClick={() => removeBookMetadata(index)}
            IconLeft={FaMinus}
          />
          <div className="type">{bookMetadata.groupId}</div>
          <div className="year">{bookMetadata.year}</div>
        </div>
      );
    });

    if (!items.length) {
      return <div className="hint">Hint: You can add multiple codes</div>;
    }

    return (
      <div className="selected-book-ref">
        <h4>Selected codes</h4>
        {items}
      </div>
    );
  }, [selectedBooksMetadata, removeBookMetadata]);

  return (
    <div className="book-and-code-selector">
      <h4>Select code(s)</h4>
      {dropdowns}
      {allBookMetadata}
    </div>
  );
};
