import React, { useEffect, useMemo, useRef, useState } from "react";
import { PlanQA, PlanModel } from "../../../model/blueprintServer";
import { formatImageUrl } from "../../../util/imageUtil";
import "./planAnnotator.scss";
import classNames from "classnames";
import { CanvasWithAnnotations } from "./CanvasWithAnnotations";
import { useCurrenPlanQaData } from "../../../atom/planQaAtom";
import { componentToCanvasAnnotation } from "../../canvas/util/canvasAnnotationUtil";
import { adminApi } from "../../../api/adminApi";

interface PlanViewerProps {
  plan: PlanModel;
}

export const PlanAnnotator = ({ plan }: PlanViewerProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [renderedPlanQa, setRenderedPlanQa] = useState<PlanQA | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const currentPlanQa = useCurrenPlanQaData();
  const [fullPlan, setFullPlan] = useState<PlanModel | null>(null);

  useEffect(() => {
    if (renderedPlanQa?.planQaId !== currentPlanQa.nullableData()?.planQaId) {
      setPageNumber(0);
      setRenderedPlanQa(currentPlanQa.data() ?? null);
      toolbarRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [
    renderedPlanQa,
    setPageNumber,
    setRenderedPlanQa,
    currentPlanQa,
    toolbarRef,
  ]);

  const sidebar = useMemo(() => {
    return plan.pages.map((page, index) => (
      <div
        className={classNames("item", { selected: pageNumber === index })}
        onClick={() => setPageNumber(index)}
        key={page.id}
      >
        <img src={formatImageUrl(page.thumbnailBlob)} />
        <div className="index">{index + 1}</div>
      </div>
    ));
  }, [plan, pageNumber, setPageNumber]);

  const imageBlob = useMemo(
    () => plan.pages[pageNumber].pageImageBlob,
    [plan, pageNumber],
  );

  useEffect(() => {
    if (!fullPlan)
      adminApi.planPages(plan.id).then((fullPlan) => {
        setFullPlan(fullPlan);
      });
  }, [fullPlan, plan.id]);

  const extraAnnotations = useMemo(() => {
    if (!fullPlan) {
      return [];
    }

    const page = fullPlan.pages[pageNumber];
    return page.components.map((component) =>
      componentToCanvasAnnotation(component, pageNumber, { color: "ff0000" }),
    );
  }, [pageNumber, fullPlan]);

  const isLoaded = imageBlob && currentPlanQa.isDone();

  return (
    <div className="flex plan-annotator">
      <div className="sidebar flex" ref={toolbarRef}>
        {sidebar}
      </div>
      {isLoaded && (
        <CanvasWithAnnotations
          pageImageBlob={imageBlob}
          pageNumber={pageNumber}
          planQa={currentPlanQa.data()}
          extraAnnotations={extraAnnotations}
        />
      )}
    </div>
  );
};
