import React, { useState } from "react";
import "./canvasMenu.scss";
import classNames from "classnames";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useRecoilState } from "recoil";

export const CanvasMenu = () => {
  const [{ zoom }, setPageImageSizeAtom] = useRecoilState(pageImageSizeAtom);

  const zoomIn = () =>
    setPageImageSizeAtom((old) => ({
      ...old,
      zoom: Math.min(3, old.zoom + 0.25),
    }));
  const zoomOut = () =>
    setPageImageSizeAtom((old) => ({
      ...old,
      zoom: Math.max(1, old.zoom - 0.25),
    }));

  const [isExpended, setIsExpended] = useState(false);
  return (
    <div
      className={classNames("canvas-menu", {
        expanded: isExpended,
        collapsed: !isExpended,
      })}
      onClick={() => setIsExpended((old) => !old)}
    >
      <div className="button" onClick={zoomIn}>
        <FaPlus />
      </div>
      <div className="zoom">{zoom * 100}%</div>
      <div className="button" onClick={zoomOut}>
        <FaMinus />
      </div>
    </div>
  );
};
