import {
  useCurrenPlanQaData,
  usePlanQaListData,
} from "../../../atom/planQaAtom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useUpdateCurrentPlanQa = () => {
  const { planQaId } = useParams();
  const planQaList = usePlanQaListData();
  const currenPlanQaData = useCurrenPlanQaData();

  useEffect(() => {
    if (
      !planQaId ||
      planQaList.isNotDone() ||
      planQaId === currenPlanQaData.nullableData()?.planQaId
    ) {
      return;
    }

    const planQa =
      planQaList.data().find((planQa) => planQa.planQaId === planQaId) ?? null;
    currenPlanQaData.update(planQa);
    currenPlanQaData.endLoading();
  }, [currenPlanQaData, planQaId, planQaList]);
};
