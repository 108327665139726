import {
  Component,
  DataLoadingStates,
  PlanModel,
} from "../../model/blueprintServer";
import React, { useEffect, useState } from "react";
import { formatImageUrl } from "../../util/imageUtil";
import { Button } from "../theme/Button";
import "./adminPlanUI.scss";
import { useModal } from "../theme/useModal";
import AdminUpdateComponentData, {
  AdminUpdateComponentDataData,
} from "./AdminUpdateComponentData";
import { atom, useRecoilState } from "recoil";
import classNames from "classnames";
import { CreateSubComponentButton } from "./CreateSubComponentButton";

export const adminPlanAtom = atom<PlanModel>({
  key: "adminPlan",
  default: {
    id: "",
    pages: [],
    title: "",
    originalFile: {
      bucket: "",
      md5: "",
      id: "",
    },
    created: new Date(),
    updated: new Date(),
    status: DataLoadingStates.PENDING_DATA_EXTRACTION,
    projectId: "",
  },
});

export const AdminPlanUI = ({ plan }: { plan: PlanModel }) => {
  const [seeJson, setSeeJson] = useState(false);
  const { showModal } = useModal<AdminUpdateComponentDataData>();
  const [localPlan, setLocalPlan] = useRecoilState(adminPlanAtom);

  useEffect(() => {
    setLocalPlan(plan);
  }, [setLocalPlan, plan]);

  return (
    <div className="plan-container">
      <button className="see-json" onClick={() => setSeeJson((old) => !old)}>
        {seeJson ? "Hide JSON" : "See JSON"}
      </button>
      {seeJson && <pre>{JSON.stringify(localPlan, null, "\t")}</pre>}
      <div className="plan-details">
        <div className="plan-item">
          <strong>Plan Id:</strong> {localPlan.id}
        </div>
        <div className="plan-item">
          <strong>Project Id:</strong> {localPlan.projectId}
        </div>
        <div className="plan-item">
          <strong>Status:</strong> {localPlan.status}
        </div>
        <div className="plan-item">
          <strong>Title:</strong> {localPlan.title}
        </div>
        <div className="plan-item">
          <strong>Created:</strong> {new Date(localPlan.created).toDateString()}
        </div>
        <div className="plan-item">
          <strong>File URL</strong>:{" "}
          <a href={formatImageUrl(localPlan.originalFile)}>PDF</a>
        </div>
        <div className="project-pages">
          <strong>Components:</strong>
          {localPlan.pages.map((page, pageNumber) => (
            <div className="page-container" key={page.id}>
              <h2>Page {pageNumber}</h2>
              <div>
                <strong>Page ID:</strong> {page.id}
              </div>
              <div className="page-item">
                <img
                  src={formatImageUrl(page.pageImageBlob)}
                  className="page-image"
                />
                <h4>Components</h4>
                <table className="component-table">
                  <thead>
                    <tr>
                      <th>Parent</th>
                      <th>Title</th>
                      <th>Data</th>
                      <th>Categories</th>
                      <th>Description</th>
                      <th>Image</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortComponentsForAdmin(page.components)
                      .filter((c) => c && c.data)
                      .map((c, componentIndex) => (
                        <tr key={componentIndex}>
                          <td
                            className={classNames({
                              child: !!c.parentComponentId,
                            })}
                          >
                            {c.parentComponentId ? "Child" : "Parent"}
                          </td>
                          <td>{c.data.title}</td>
                          <td>{c.data.extractedData}</td>
                          <td>{c.data.categories.join(", ")}</td>
                          <td>{c.data.description}</td>
                          <td>
                            <img
                              src={formatImageUrl(c.imageBlobStorage)}
                              className="component-image"
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                showModal(AdminUpdateComponentData, {
                                  pageNumber,
                                  planId: localPlan.id,
                                  initialComponentIndex: componentIndex,
                                  plan: localPlan,
                                })
                              }
                            >
                              Edit
                            </Button>
                          </td>
                          <td>
                            <CreateSubComponentButton
                              planId={plan.id}
                              componentId={c.componentId}
                              pageNumber={pageNumber}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const sortComponentsForAdmin = (components: Component[]) => {
  const componentMap = new Map();

  // Group components by ID for quick lookup
  components.forEach((component) => {
    componentMap.set(component.componentId, component);
  });

  const sorted: Component[] = [];
  const visited: Set<string> = new Set();

  const addWithChildren = (component: Component) => {
    if (visited.has(component.componentId)) return;
    visited.add(component.componentId);
    sorted.push(component);

    components
      .filter((child) => child.parentComponentId === component.componentId)
      .forEach(addWithChildren);
  };

  components
    .filter((component) => !component.parentComponentId)
    .forEach(addWithChildren);

  return sorted;
};
