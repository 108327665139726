import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  planQaQuestionsAtom,
  usePlanQaQuestions,
} from "../../../../atom/questionAtom";
import {
  SectionRef,
  useFetchBuildingCode,
} from "../../hook/useFetchBuildingCode";
import { questionApi } from "../../../../api/questionApi";
import { currentProjectsAtom } from "../../../../atom/currentProjectAtom";
import { useParams } from "react-router-dom";

export const useLoadPlanQaQuestions = () => {
  const fetchCode = useFetchBuildingCode();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const planQaQuestions = usePlanQaQuestions();
  const resetQuestions = useResetRecoilState(planQaQuestionsAtom);
  const { planQaId } = useParams();
  const [currentPlanQaId, setCurrentPlanQaId] = useState("");

  useEffect(() => {
    if (
      !planQaId ||
      currentPlanQaId === planQaId ||
      planQaQuestions.isLoading() ||
      !currentProject.model
    ) {
      return;
    }

    setCurrentPlanQaId(planQaId);
    resetQuestions();
    planQaQuestions.startLoading();
    questionApi.getAll(planQaId).then((data) => {
      planQaQuestions.update(data);
      planQaQuestions.endLoading();
      if (!currentProject.model) {
        return;
      }

      const {
        jurisdiction: { codeYear, cityName },
      } = currentProject.model;
      const sectionsRef: SectionRef[] = [];
      data.forEach((question) => {
        question.answer?.relevantSections.forEach((section) =>
          sectionsRef.push({
            cityName,
            codeYear,
            fullSectionPath: section.sectionPath,
            bookId: section.bookId,
          } as SectionRef),
        );
      });
      fetchCode(sectionsRef);
    });
  }, [
    planQaQuestions,
    currentPlanQaId,
    setCurrentPlanQaId,
    planQaId,
    currentProject.model,
    fetchCode,
    resetQuestions,
  ]);
};
